import { fetchGraphQL } from './fetchApi';

const operationsDoc = (configSchema) => `
  query getScreenAccess($role_id:[Int!]){
    ${configSchema}_mbd_roles_to_screens(where: {role_id: {_in:$role_id},access_level:{_eq:"S"}} ,distinct_on:screen_id) {
      roleId: role_id
      screenId: screen_id  
      accessLevel: access_level            
      mbdScreen: mbd_screen {
        id
        screenInternalName: screen_internal_name
        screenDisplayNameen: screen_display_name_en
        screenDisplayNamede: screen_display_name_de
        menuName: menu_name
      }
    }
  }
  `;

function fetchGetAccessControlDetails(roleId, configSchema) {
  return fetchGraphQL(operationsDoc(configSchema), 'getScreenAccess', { role_id: roleId });
}

async function startFetchGetAccessControlDetails(roleId, configSchema) {
  const { errors, data } = await fetchGetAccessControlDetails(roleId, configSchema);

  if (errors) {
    // handle those errors like a pro
    console.error(errors);
  }

  // do something great with this precious data
  return data;
}

export const getAccessControlDetails = (roleId, configSchema) => startFetchGetAccessControlDetails(roleId, configSchema);
