import { useEffect, useState } from 'react';
import { SideNav, SideNavCategory, SideNavItem, SideNavSection } from '@airbus/components-react';
import { useUserContextApi } from '../../context/UserContext';
import { useRouter } from 'next/navigation';
import styles from './SideNavigation.module.css';
import { useAllScreensContext } from '../../context/AllScreensDetailsContext';

function SideNavigation(props) {
  const router = useRouter();
  const [screenDetails, setScreenDetails] = useState([]);
  const { menuGroup } = useAllScreensContext();
  const { accessControlDetails } = useUserContextApi();
  const { showSideNav, setShowSideNav } = props;
  const [category, setCategory] = useState({});

  useEffect(() => {
    if (menuGroup) {
      const accessibleScreens = accessControlDetails.map(
        (item) => item.mbdScreen['screenInternalName'],
      );
      const filteredScreens = menuGroup
        .map((item) => {
          const filteredSubMenu = item.subMenu
            .map((subMenuItem) => {
              let filteredSubChildMenu = [];
              if (Array.isArray(subMenuItem.subChildMenu)) {
                filteredSubChildMenu = subMenuItem.subChildMenu.filter((childItem) =>
                  accessibleScreens.includes(childItem.appNameId),
                );
              }
              return { ...subMenuItem, subChildMenu: filteredSubChildMenu };
            })
            .filter((subMenuItem) => accessibleScreens.includes(subMenuItem.appNameId));
          return filteredSubMenu.length > 0 ? { ...item, subMenu: filteredSubMenu } : null;
        })
        .filter((item) => item != null);
      setScreenDetails(filteredScreens);
    }
  }, [accessControlDetails, menuGroup]);

  const navigateTo = (appName: string, screenName: number) => {
    const route = appName === 'NPI Tool Administration' ? `/admin` : `/mbdcp`;
    router.push(`${route}/${screenName}`);
  };

  const toggleCategory = (id) => {
    setCategory((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <SideNav
      theme='light'
      open={showSideNav}
      onClose={() => setShowSideNav(false)}
      aria-label='Main Navigation'
      className={styles.sideNavigationPadding}
    >
      <SideNavSection>
        {screenDetails &&
          screenDetails.map((obj) => {
            return (
              <SideNavCategory
                key={obj.id}
                label={obj.appName}
                open={category[obj.id]}
                onSelected={() => toggleCategory(obj.id)}
              >
                {obj.subMenu
                  .sort((a, b) => a.sortOrder - b.sortOrder)
                  .map((subMenu) => {
                    if (subMenu.subChildMenu?.length) {
                      return (
                        <SideNavCategory
                          key={subMenu.id}
                          label={subMenu.screenName}
                          open={category[subMenu.id]}
                          onSelected={() => toggleCategory(subMenu.id)}
                        >
                          {subMenu.subChildMenu
                            .sort((a, b) => a.sortOrder - b.sortOrder)
                            .map((subItem) => {
                              return (
                                <SideNavItem
                                  key={subItem.id}
                                  onSelected={() => {
                                    navigateTo(subItem.appName, subItem.appNameId);
                                  }}
                                  data-testid='sidenav'
                                >
                                  {subItem.screenName}
                                </SideNavItem>
                              );
                            })}
                        </SideNavCategory>
                      );
                    } else {
                      return (
                        <SideNavItem
                          key={subMenu.id}
                          onSelected={() => {
                            navigateTo(subMenu.appName, subMenu.appNameId);
                          }}
                          data-testid='sidenav'
                        >
                          {subMenu.screenName}
                        </SideNavItem>
                      );
                    }
                  })}
              </SideNavCategory>
            );
          })}
      </SideNavSection>
    </SideNav>
  );
}
export default SideNavigation;
