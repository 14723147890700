import { useEffect, useRef } from 'react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useConfigContext } from './ConfigContext';
import { useRouter } from 'next/router';

export default function PostHogProviderWrapper({ children }) {
  const { postHogKey, postHogHost } = useConfigContext();
  const router = useRouter();
  const oldUrlRef = useRef('');

  useEffect(() => {
    posthog.init(postHogKey, {
      api_host: postHogHost,
      person_profiles: 'identified_only',
      // Enable debug mode in development
      loaded: (posthog) => {
        if (process.env.NODE_ENV === 'development') posthog.debug();
      },
    });

    const handleRouteChangeStart = () =>
      posthog?.capture('$pageleave', {
        $current_url: oldUrlRef.current,
      });
    const handleRouteChange = () => posthog?.capture('$pageview');

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [postHogKey, postHogHost]);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
