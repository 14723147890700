import { fetchGraphQL } from './fetchApi';
const operationsDoc = (configSchema) => `   
    query GetUserRoleDetails($user_id: Int) {
      ${configSchema}_mbd_user_to_role(where: {user_id: {_eq: $user_id}}) {
        userId: user_id
        roleId: role_id
        mbdUserByUserId{
          firstName: firstname
          lastName: lastname
        }
      }
    }
  `;

function fetchGetUserRoleDetails(userId, configSchema) {
  return fetchGraphQL(operationsDoc(configSchema), 'GetUserRoleDetails', { user_id: userId });
}

async function startFetchGetUserRoleDetails(userId, configSchema) {
  const { errors, data } = await fetchGetUserRoleDetails(userId, configSchema);

  if (errors) {
    // handle those errors like a pro
    console.error(errors);
  }

  // do something great with this precious data
  return data;
}

export const getUserDetails = (userId, configSchema) => startFetchGetUserRoleDetails(userId, configSchema);
