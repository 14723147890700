'use client';

import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey(
  '84e15bd700abe8c43f04b39ab4821a8aTz0xMDM5NzAsRT0xNzY4MjYyNDAwMDAwLFM9cHJlbWl1bSxMTT1hbm51YWwsUFY9UTMtMjAyNCxLVj0y',
);

export default function MuiXLicense() {
  return null;
}
