import { fetchGraphQL } from './fetchApi';

function fetchMyQuery(operationsDoc, operationName, limit, offset) {
  const variables = limit === 0 ? {} : { limit, offset };
  return fetchGraphQL(operationsDoc, operationName, variables);
}

async function startFetchMyQuery(operationQuery, operationName, limit, offset) {
  const { errors, data } = await fetchMyQuery(operationQuery, operationName, limit, offset);

  if (errors) {
    // handle those errors like a pro
    console.error(errors);
  }

  // do something great with this precious data
  return data;
}

export const getData = (operationQuery, operationName = 'MyQuery', limit = 0, offset = 0) =>
  startFetchMyQuery(operationQuery, operationName, limit, offset);
