import React, { useState } from 'react';
import { useRouter } from 'next/router';

import { Box } from '@mui/material';
import { Card, CardContent, CardHeader, Inline, Typography } from '@airbus/components-react';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import styles from './DisplayScreens.module.css';
import { ScreenData } from '../../shared/types';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface DisplayScreenProps {
  selectedTab: number;
  screenAccessDetails: ScreenData;
  noScreenAccessDetails: ScreenData;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const sortScreensFn = (screenA: ScreenData[number], screenB: ScreenData[number]) => {
  const textA = screenA.screenName.toUpperCase();
  const textB = screenB.screenName.toUpperCase();
  return textA > textB === true ? 1 : -1;
};

const DisplayScreens = (props: DisplayScreenProps) => {
  const { selectedTab, screenAccessDetails, noScreenAccessDetails } = props;
  const [adminApps] = useState(['NPI Tool Administration']);
  const { push } = useRouter();

  const businessAccessScreens = screenAccessDetails
    ?.filter((screenData) => !adminApps.includes(screenData.parentAppName))
    .sort(sortScreensFn)
    ?.map((screenData) => {
      return (
        <Card
          key={screenData.id}
          className={styles.card}
          onClick={() => push(`mbdcp/${screenData.appNameId}`)}
        >
          <CardHeader icon={<AirplanemodeActiveIcon />} title={screenData.screenName} />
        </Card>
      );
    });

  const adminAccessScreens = screenAccessDetails
    ?.filter((screenData) => adminApps.includes(screenData.parentAppName))
    ?.sort(sortScreensFn)
    ?.map((screenData) => {
      return (
        <Card
          key={screenData.id}
          className={styles.card}
          onClick={() => push(`admin/${screenData.appNameId}`)}
        >
          <CardHeader icon={<AirplanemodeActiveIcon />} title={screenData.screenName} />
          <CardContent></CardContent>
        </Card>
      );
    });

  const businessNoAccessScreens = noScreenAccessDetails
    ?.filter((screenData) => !adminApps.includes(screenData.parentAppName))
    ?.sort(sortScreensFn)
    ?.map((screenData) => {
      return (
        <Card key={screenData.id} className={styles.dimCard}>
          <CardHeader icon={<AirplanemodeActiveIcon />} title={screenData.screenName} />
          <CardContent></CardContent>
        </Card>
      );
    });

  const adminNoAccessScreens = noScreenAccessDetails
    ?.filter((screenData) => adminApps.includes(screenData.parentAppName))
    ?.sort(sortScreensFn)
    ?.map((screenData) => {
      return (
        <Card key={screenData.id} className={styles.dimCard}>
          <CardHeader icon={<AirplanemodeActiveIcon />} title={screenData.screenName} />
          <CardContent></CardContent>
        </Card>
      );
    });

  return (
    <div>
      {/* Business Screens */}
      <CustomTabPanel value={selectedTab} index={0}>
        <Typography variant='h6'>{`Business Screens`}</Typography>
        <Inline wrap spacing='2-x'>
          {businessAccessScreens}
          {businessNoAccessScreens}
        </Inline>
      </CustomTabPanel>
      {/* Admin Screens */}
      <CustomTabPanel value={selectedTab} index={0}>
        <Typography variant='h6'>{`Admin Screens`}</Typography>
        <Inline wrap spacing='2-x'>
          {adminAccessScreens}
          {adminNoAccessScreens}
        </Inline>
      </CustomTabPanel>
    </div>
  );
};

export default DisplayScreens;
