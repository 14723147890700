import { fetchGraphQL } from './fetchApi';
const operationsDoc = (configSchema) => `   
    query getUserDetails($user_account_id: String) {
      ${configSchema}_mbd_users(where: {user_account_id: {_eq: $user_account_id}}) {
        id
        userAccountId: user_account_id
      }
    }
  `;

function fetchGetUserIdDetails(userAccountId, configSchema) {
  return fetchGraphQL(operationsDoc(configSchema), 'getUserDetails', { user_account_id: userAccountId });
}

async function startFetchGetUserIdDetails(userAccountId, configSchema) {
  const { errors, data } = await fetchGetUserIdDetails(userAccountId, configSchema);

  if (errors) {
    // handle those errors like a pro
    console.error(errors);
  }

  // do something great with this precious data
  return data;
}

export const getUserIdDetails = (userAccountId, configSchema) => startFetchGetUserIdDetails(userAccountId, configSchema);
