import {
  Header,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ColorModeProvider,
} from '@airbus/components-react';
import { KeyboardArrowDown } from '@airbus/icons/react';
import { useUserContextApi } from '../../context/UserContext';
import styles from './header.module.css';
import LanguageSwitcher from './LanguageSwitcher';
import { useRouter } from 'next/router';
import LoginButton from '../auth/LoginButton';
import { t } from '../../../i18next';
import { useConfigContext } from '../../context/ConfigContext';

function Headers(props) {
  const { setShowSideNav } = props;
  const { appName } = useConfigContext();

  const {
    loginDetails,
    userDetails,
    updateLoginDetails,
    updateUserDetails,
    updateAccessControlDetails,
  } = useUserContextApi();
  const userName = userDetails?.mbdUserByUserId
    ? `${userDetails?.mbdUserByUserId?.firstName} ${userDetails?.mbdUserByUserId?.lastName}`.trim()
    : loginDetails.userId;

  const router = useRouter();
  async function handleLogout() {
    const response = await fetch('/api/auth/session', {
      method: 'DELETE',
      headers: { Accept: 'application/json' },
    });
    if (response.ok) {
      // Reset all UserContext state
      updateLoginDetails({ userId: null });
      updateUserDetails({ userId: null, roleIds: null, mbdUserByUserId: {} });
      updateAccessControlDetails([]);
      router.push('/');
    }
  }

  async function handleProfile() {}

  const user = [
    { name: t('profile'), fn: handleProfile },
    { name: t('logout'), fn: handleLogout },
  ];

  return (
    <Header
      appName={appName || t('app_name')}
      showMenuButton
      onMenuButtonClick={() => setShowSideNav((prestate) => !prestate)}
      onHomeClick={() => router.push('/')}
      className={styles.header}
    >
      {loginDetails.userId ? (
        <Menu>
          <MenuButton variant='ghost'>
            {userName}
            <KeyboardArrowDown className={styles.arrowDownMenuIcon} />
          </MenuButton>
          <ColorModeProvider mode='dark'>
            <MenuList>
              {user.map((userInfoObj) => {
                return (
                  <MenuItem key={userInfoObj.name} onClick={userInfoObj.fn}>
                    {userInfoObj.name}
                  </MenuItem>
                );
              })}
            </MenuList>
          </ColorModeProvider>
        </Menu>
      ) : (
        <LoginButton />
      )}
      <LanguageSwitcher />
    </Header>
  );
}

export default Headers;
