import React, { Fragment, useState } from 'react';
import Headers from './header';
import Footers from './footer';
import MuiXLicense from './muiXLicense';
import SideNavigation from './SideNavigation';
import { useLanguageContextAPI } from '../../context/LanguageContext';
import { useUserContextApi } from '../../context/UserContext';
import { Container, Typography } from '@airbus/components-react';
import styles from './index-main.module.css';

export default function Layout(props) {
  const { children } = props;
  const { language } = useLanguageContextAPI();
  const { userDetails } = useUserContextApi();
  const [showSideNav, setShowSideNav] = useState(false);
  const screenName = userDetails?.appDetails
    ? userDetails.appDetails[`screenDisplayName${language}`]
    : '';
  return (
    <Fragment>
      <Headers {...{ setShowSideNav }} />
      <SideNavigation {...{ showSideNav, setShowSideNav }} />
      <Container component='main' className={styles.main}>
        <Typography variant='h6'>{screenName}</Typography>
        {children}
        <MuiXLicense />
      </Container>
      <Footers />
    </Fragment>
  );
}
