import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { getData } from '../services/getDataService';
import { useConfigContext } from './ConfigContext';
import { useLanguageContextAPI } from './LanguageContext';

const AllScreensDetailsContext = createContext(null);

export default function AllScreensDetailsContextProvider({ children }) {
  const [allScreenDetails, setAllScreenDetails] = useState([]);
  const [menuGroup, setMenuGroup] = useState([]);
  const { configSchema } = useConfigContext();
  const { language } = useLanguageContextAPI();

  const operationsDoc = `query getMenuStructure {
    ${configSchema}_mbd_menu_structure(where: {parent_menu_id: {_is_null:true}active:{_eq:true}} order_by: {sort_order_no:asc}) {
      id
      mbdParentMenu: mbd_menu{
        parentMenuInternalName: menu_internal_name
        parentMenuDisplayNameen: menu_display_name_en
        parentMenuDisplayNamede: menu_display_name_de
      }
      mbd_menu_structures (where: {active:{_eq:true}} order_by: {sort_order_no:asc}) {
        id
        sortOrder: sort_order_no
        mbd_menu{
          menu_internal_name
          menu_display_name_en
          menu_display_name_de
        }  
          mbd_menu_structures (where: {active:{_eq:true}} order_by: {sort_order_no:asc}) {
            id
            sortOrder: sort_order_no
            mbd_menu{
              menu_internal_name
              menu_display_name_en
              menu_display_name_de
            }    
          }
      }
    }
  }
  `
  
  useEffect(() => {
    async function fetchAllScreens() {
      try {
        const response = await getData(operationsDoc, 'getMenuStructure');
        setAllScreenDetails(response[`${configSchema}_mbd_menu_structure`]);
      } catch (error) {
        return error;
      }
    }
    fetchAllScreens();
  }, []);

  useEffect(() => {
    if (allScreenDetails.length > 0) {
      const menu = allScreenDetails.reduce((arr, item) => {
        const { id, mbdParentMenu, mbd_menu_structures } = item;
        const parentMenuName = mbdParentMenu[`parentMenuDisplayName${language}`];
        arr.push({
          id,
          appName: parentMenuName,
          subMenu: [],
        });
        const parentIndex = arr.findIndex((elem) => elem.appName === parentMenuName);
        mbd_menu_structures.forEach((item) => {
          const childMenu = {
            id: item.id,
            appName: parentMenuName,
            appNameId: item.mbd_menu.menu_internal_name,
            screenName: item.mbd_menu[`menu_display_name_${language}`],
            sortOrder: item.sortOrder,
            subChildMenu: [],
          };
          if (item.mbd_menu_structures?.length) {
            childMenu.subChildMenu = item.mbd_menu_structures
              .map((item) => ({
                id: item.id,
                appNameId: item.mbd_menu.menu_internal_name,
                screenName: item.mbd_menu[`menu_display_name_${language}`],
                sortOrder: item.sortOrder,
              }))
              .sort((a, b) => a.sortOrder - b.sortOrder);
          }
          arr[parentIndex].subMenu.push(childMenu);
        });
        arr[parentIndex].subMenu.sort((a, b) => a.sortOrder - b.sortOrder);
        return arr;
      }, []);
      setMenuGroup(menu);
    }
  }, [allScreenDetails, language]);

  const value = useMemo(() => ({ menuGroup }), [menuGroup]);

  return (
    <AllScreensDetailsContext.Provider value={value}>{children}</AllScreensDetailsContext.Provider>
  );
}

export function useAllScreensContext() {
  const context = useContext(AllScreensDetailsContext);
  if (context === undefined) {
    throw new Error('Screen Details Context must be used within a Provider');
  }
  return context;
}
