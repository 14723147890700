import { ReactElement, useEffect, useState } from 'react';

import { Typography } from '@airbus/components-react';

import styles from './index.module.css';

import { useLanguageContextAPI } from '../shared/context/LanguageContext';
import { useUserContextApi } from '../shared/context/UserContext';

import Layout from '../shared/components/layout';
import Spinners from '../shared/components/Spinner';
import ScreenTabs from '../features/landing_page/ScreenTabs';
import DisplayScreens from '../features/landing_page/DisplayScreens';

import { AccessData, ScreenData, UserData, UserDetailsData } from '../shared/types';
import { AlertBar } from '../shared/components/AlertBar';
import { useAllScreensContext } from '../shared/context/AllScreensDetailsContext';

export default function Home() {
  const {
    loginDetails,
    accessControlDetails,
    userDetails,
    updateUserDetails,
  }: {
    loginDetails: UserData;
    accessControlDetails: AccessData;
    userDetails: UserDetailsData;
    updateUserDetails: React.Dispatch<UserDetailsData>;
  } = useUserContextApi();
  const { menuGroup } = useAllScreensContext();
  const { t } = useLanguageContextAPI();
  //   Screens State
  const [noScreenAccessDetails, setNoScreenAccessDetails] = useState<ScreenData>([]);
  const [screenAccessDetails, setScreenAccessDetails] = useState<ScreenData>([]);
  //   Tab State
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [poAlertbar, setPoAlertBar] = useState<{
    children: ReactElement | string;
    severity: 'success' | 'warning' | 'error';
    stopAutoClose?: boolean;
  }>(null);

  const allSubMenusWithParent = (input) => {
    return input.flatMap((screenData) => {
      return screenData.subMenu.map((subMenuData) => {
        return {
          parentAppName: screenData.appName,
          ...subMenuData,
        };
      });
    });
  };

  useEffect(() => {
    setLoading(false);
    if (accessControlDetails.length) {
      const accessibleScreens = accessControlDetails.map(
        (item) => item['mbdScreen'].screenInternalName,
      );

      if (menuGroup.length) {
        const filteredAccessScreens = menuGroup
          .map((item) => {
            const mergedSubMenu = item.subMenu.flatMap((item) => {
              return item.subChildMenu ? [item, ...item.subChildMenu] : [item];
            });
            const uniqueSubMenu = Array.from(
              new Map(mergedSubMenu.map((item) => [item.appNameId, item])).values(),
            );
            const filteredSubMenu = uniqueSubMenu.filter((subMenuItem) =>
              accessibleScreens.includes(subMenuItem?.appNameId),
            );
            return filteredSubMenu.length > 0 && { ...item, subMenu: filteredSubMenu };
          })
          .filter((item) => item);
        const updatedScreensAccess = allSubMenusWithParent(filteredAccessScreens);
        setScreenAccessDetails(updatedScreensAccess);

        const filteredNoAccessScreens = menuGroup
          .map((item) => {
            const mergedSubMenu = item.subMenu.flatMap((item) => {
              return item.subChildMenu ? [item, ...item.subChildMenu] : [item];
            });
            const uniqueSubMenu = Array.from(
              new Map(mergedSubMenu.map((item) => [item.appNameId, item])).values(),
            );
            const filteredSubMenu = uniqueSubMenu.filter(
              (subMenuItem) => !accessibleScreens.includes(subMenuItem.appNameId),
            );
            return filteredSubMenu.length > 0 && { ...item, subMenu: filteredSubMenu };
          })
          .filter((item) => item);
        const updatedNoScreensAccess = allSubMenusWithParent(filteredNoAccessScreens);
        setNoScreenAccessDetails(updatedNoScreensAccess);
      }
    }
  }, [accessControlDetails, menuGroup]);

  useEffect(() => {
    updateUserDetails({ ...userDetails, app_details: null });
  }, []);

  return (
    <Layout>
      <AlertBar alertbar={poAlertbar} setAlertbar={setPoAlertBar} />
      {loginDetails.userId ? (
        <>
          <Typography variant='h5'>{t('welcome_header')}</Typography>
          <div className={styles.gridBox}>
            <ScreenTabs {...{ selectedTab, setSelectedTab, open, setOpen }} />
            {loading ? (
              <Spinners />
            ) : screenAccessDetails.length > 0 ? (
              <DisplayScreens
                {...{
                  selectedTab,
                  noScreenAccessDetails,
                  screenAccessDetails,
                }}
              />
            ) : (
              <Typography variant='h6'>{t('landing_page.no_access')}</Typography>
            )}
          </div>
        </>
      ) : (
        <Typography variant='h3'>{t('not_logged_in')}</Typography>
      )}
    </Layout>
  );
}
